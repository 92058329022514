import React from 'react';
import Default from '../default/Default';
import { Col, Container, Row } from 'react-bootstrap';
import "./Landing.css"

const Landing = (props) => {

    return (
        <Default>

            {/* <div style={{ marginTop: 68 }}>
                <Row>
                    <Col md="6" className='content-container-first-section'>
                            <div className="title-1">
                                Automate <span className='text-purpule'>Your <br /> QuickBooks </span> Reporting
                            </div>
                            <div className='discription-1 mt-4'>
                                Seamlessly access, download, <br />
                                and distribute your financial reports on <br />
                                scheduled intervals
                            </div>
                            <button className='theme-button my-5'>Try Now for Free</button>
                    </Col>
                    <Col md="6" className='text-end'>
                        <img src={require("../../assets/images/image_1.png")} height={583} width={727} alt="" />
                    </Col>
                </Row>
            </div> */}

            <Container style={{ marginTop: 55 }}>
                <Row>
                    <Col md="6">
                        <div className="title-1">
                            Automate <span className='text-purpule'>Your <br /> QuickBooks </span> Reporting
                        </div>
                        <div className='discription-1'>
                            Seamlessly access, download, <br />
                            and distribute your financial reports on <br />
                            scheduled intervals
                        </div>
                        <button className='theme-button mb-5'>Try Now for Free</button>
                    </Col>
                    <Col md="6" className='text-end'>
                        <img src={require("../../assets/images/image_1.png")} className='image-1' alt="" />
                    </Col>
                </Row>
            </Container>

            <Container className="power-by-container">
                <div className='power-by-text'>powered by</div>
                <img src={require("../../assets/images/qb.png")} alt="" />
            </Container>

            <Container className="text-center" id='how-it-works'>
                <div className='check-out-text'>Check out how it works...</div>
                <img src={require("../../assets/images/video-tv.png")} className='video-tv-image' alt="" />
            </Container>

            <Container className='mb-140-px' id='features'>
                <Row>
                    <Col md="5" className='content-container'>
                        <div className="content-nunito-bold-32">
                            Effortlessly Access, Manage & <br /> Share Your Financial Reports
                        </div>
                        <div className='open-sans-20' style={{ marginTop: 23, marginBottom: 44 }}>
                            Rapid Reports is a cutting-edge application designed <br />
                            to revolutionize the way QuickBooks users handle <br />
                            their financial reporting. Users can seamlessly access, <br />
                            download, and distribute your financial reports on <br />
                            scheduled intervals. Say goodbye to manual report <br />
                            generation and embrace the power of automation <br />
                            with Rapid Reports.
                        </div>
                        <button className='theme-button'>Try Now for Free</button>
                    </Col>
                    <Col md="7" className='text-center'>
                        <img src={require("../../assets/images/image_2.png")} className='image-2' alt="" />
                    </Col>
                </Row>
            </Container>

            <Container className='mb-140-px'>
                <Row>
                    <Col md="7" >
                        <img src={require("../../assets/images/image_3.png")} className='image-3' alt="" />
                    </Col>
                    <Col md="5" className='content-container'>
                        <div className="content-nunito-bold-32">
                            Create Distributions & <br /> Specify Export or Email
                        </div>
                        <div className='open-sans-20' style={{ marginTop: 14, marginBottom: 40.59 }}>
                            Simply select the reports you want to include and define  <br />
                            the distribution frequency, ranging from daily to annual <br />
                            intervals. Next, specify whether you prefer to export the <br />
                            reports or distribute them via email. Rapid Reports  <br />
                            empowers you to customize your financial reporting <br />
                            workflow to suit your unique needs.
                        </div>
                        <button className='theme-button'>Try Now for Free</button>
                    </Col>
                </Row>
            </Container>

            <Container className="mb-158-px">
                <Row>
                    <Col md="6" className='content-container'>
                        <div className="content-nunito-bold-32">
                            Download Report Packages with a Click
                        </div>
                        <div className='open-sans-20' style={{ marginTop: 29.2 }}>
                            Choosing “Export” will automatically generate report packages <br />
                            based on your distribution settings. These packages act as <br />
                            organized collections of reports, ensuring easy access and <br />
                            retrieval. With just a few clicks, you can effortlessly download the <br />
                            report packages directly from our user-friendly interface, in the <br />
                            form of Excel or PDF. To safeguard sensitive financial information, <br />
                            we also provide the option to password protect the exported <br />
                            packages.
                        </div>
                    </Col>
                    <Col md="6">
                        <img src={require("../../assets/images/image_4.png")} className='image-4' alt="" />
                    </Col>
                </Row>
            </Container>

            <Container className='mb-140-px'>
                <Row>
                    <Col md="6">
                        <img src={require("../../assets/images/image_5.png")} className='image-5' alt="" />
                    </Col>
                    <Col md="6">
                        <div className="content-nunito-bold-32">
                            Efficient Email Distribution:
                        </div>
                        <div className='open-sans-20' style={{ marginTop: 19 }}>
                            Opting for email distribution? Rapid Reports has got you covered.   <br />
                            Configure the sender's email address, recipient email addresses,  <br />
                            subject line, and body content for each distribution. Customize <br />
                            your email messages to provide context and clarity to the  <br />
                            recipients. Our app takes care of attaching the report packages  <br />
                            automatically, making it seamless to share the reports with <br />
                            stakeholders, team members, or clients.
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="get-started">
                <div className="get-started-head">Ready to Get Started?</div>
                <div className="hr-container">
                    <div className="hr-1"></div>
                    <div className="hr-2"></div>
                </div>
                <div className="get-started-description">Try for Rapid Reports today and experience the ease and efficiency firsthand.</div>
                <button className='theme-button get-started-btn'>Try Now for Free</button>
            </Container>

        </Default>
    )
}

export default Landing