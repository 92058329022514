import React from 'react';
import './DefaultStyle.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = (props) => {
    return (
        <div className='header-main-container' id='home'>
            <div className='header-container'>
                <p className='title' >Effortlessly Access, Manage, and Share Your Financial Reports</p>
            </div>
            <div className='header-menu-container web-only'>
                <img src={require('../../assets/images/logo-black.png')} className='header-logo' alt="" />
                <div className='d-flex'>
                    <div className='header-menu-item' onClick={() => window.location.replace("/#home")}>Home</div>
                    <div className='header-menu-item' onClick={() => window.location.replace("/#how-it-works")}>How it Works</div>
                    <div className='header-menu-item' onClick={() => window.location.replace("/#features")}>Features</div>
                    <div className='header-menu-item' onClick={() => window.location.replace("/#footer")}>Contact Us</div>
                    <button className='header-btn'>Try Now for Free</button>
                </div>
            </div>

            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary header-menu-container mobile-only">
                <Container>
                    <Navbar.Brand href="">
                        <img src={require('../../assets/images/logo-black.png')} className='header-logo' alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav style={{background: "#F7F7FC", position: 'relative', zIndex: 1}}>
                            <div className='header-menu-item' onClick={() => window.location.replace("/#home")}>Home</div>
                            <div className='header-menu-item' onClick={() => window.location.replace("/#how-it-works")}>How it Works</div>
                            <div className='header-menu-item' onClick={() => window.location.replace("/#features")}>Features</div>
                            <div className='header-menu-item' onClick={() => window.location.replace("/#footer")}>Contact Us</div>
                            <div className='header-menu-item'><button className='header-btn'>Try Now for Free</button></div>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header