import React from 'react';
import Header from '../../components/default/Header';
import Footer from '../../components/default/Footer';

const Default = (props) => {

    return (
        <div style={{height: '100vh', width: "100vw", overflowX: 'hidden', overflowY: 'auto'}}>

            <Header />
            {props.children}
            <Footer />

        </div>
    )
}

export default Default