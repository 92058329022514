import React from 'react';
import './DefaultStyle.css';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = (props) => {

    window.onload = function() {
        // Check if the URL contains the anchor link
        if (window.location.hash === "#footer") {
            // Scroll to the footer element
            var footerElement = document.getElementById("footer");
            if (footerElement) {
                footerElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };



    return (
        <div className='bg-white' id='footer'>
            <Container >
                <Row className='footer-container'>
                    <Col md="4">
                        <div>
                            <img src={require('../../assets/images/logo-black.png')} height={35} width={256} alt="" />
                            <p className='footer-text'>Effortlessly Access, Manage, and <br /> Share Your Financial Reports</p>
                            <div className='d-flex mb-4'>
                                <a href="">
                                    <img src={require('../../assets/images/facebook.png')} className='social-icon' alt="" />
                                </a>
                                <a href="">
                                    <img src={require('../../assets/images/twitter.png')} className='social-icon' alt="" />
                                </a>
                                <a href="">
                                    <img src={require('../../assets/images/dribbble.png')} className='social-icon' alt="" />
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <p className='footer-title' style={{marginBottom : 31}}>Contact</p>
                        <p className='footer-link'>support@solve4u.io</p>
                    </Col>
                    <Col md="3">
                        <p className='footer-title'>Useful Links</p>
                        <a href='/#home'><p className='footer-link'>Home</p></a>
                        <a href=''><p className='footer-link'>Service</p></a>
                        <a href=''><p className='footer-link'>About Us</p></a>
                        <a href=''><p className='footer-link'>Pricing</p></a>
                    </Col>
                    <Col md="2">
                        <p className='footer-title'>Help</p>
                        <p className='footer-link'>Frequently Asked Questions</p>
                        <p className='footer-link'>YouTube channel</p>
                    </Col>
                </Row>
            </Container>
            <div className='header-container'>
                <p className='title' style={{fontWeight: 400}}>© 2023 RapidReports. All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer